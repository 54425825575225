import { useState } from "react";
import { Button, Checkbox, Modal } from "semantic-ui-react";

export function LogoutModal(props: {
  open: boolean;
  onCancel: () => void;
  onFinish: () => void;
}) {
  const [deleteMyData, setDeleteMyData] = useState(false);
  return (
    <Modal onClose={props.onCancel} open={props.open}>
      <Modal.Header>Logout</Modal.Header>
      <Modal.Content image>
        <Modal.Description>
          <p>
            Logging out will clear your authentication data from your browser.
          </p>
          <p>
            You can also delete all of your user data from Singable. You can
            always sign back up later.
          </p>
          <Checkbox
            label="Delete my data from Singable"
            checked={deleteMyData}
            onChange={(_e, data) => setDeleteMyData(data.checked ?? false)}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={props.onCancel}>Cancel</Button>
        <Button
          negative
          content="Logout"
          onClick={() => {
            const urlParams = new URLSearchParams();
            urlParams.set("deleteMyData", deleteMyData.toString());
            fetch(`/api/auth?${urlParams.toString()}`, {
              method: "DELETE",
            }).then(props.onFinish);
          }}
        />
      </Modal.Actions>
    </Modal>
  );
}
